exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dlaczego-my-js": () => import("./../../../src/pages/dlaczego-my.js" /* webpackChunkName: "component---src-pages-dlaczego-my-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-js": () => import("./../../../src/pages/kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-kariera-potwierdzenie-js": () => import("./../../../src/pages/kariera-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kariera-potwierdzenie-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-medika-1-js": () => import("./../../../src/pages/medika-1.js" /* webpackChunkName: "component---src-pages-medika-1-js" */),
  "component---src-pages-nasi-specjalisci-js": () => import("./../../../src/pages/nasi-specjalisci.js" /* webpackChunkName: "component---src-pages-nasi-specjalisci-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-chirurgia-palca-js": () => import("./../../../src/pages/oferta/chirurgia-palca.js" /* webpackChunkName: "component---src-pages-oferta-chirurgia-palca-js" */),
  "component---src-pages-oferta-dietetyka-js": () => import("./../../../src/pages/oferta/dietetyka.js" /* webpackChunkName: "component---src-pages-oferta-dietetyka-js" */),
  "component---src-pages-oferta-fizjoterapia-js": () => import("./../../../src/pages/oferta/fizjoterapia.js" /* webpackChunkName: "component---src-pages-oferta-fizjoterapia-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-oferta-kriochirurgia-js": () => import("./../../../src/pages/oferta/kriochirurgia.js" /* webpackChunkName: "component---src-pages-oferta-kriochirurgia-js" */),
  "component---src-pages-oferta-laseroterapia-js": () => import("./../../../src/pages/oferta/laseroterapia.js" /* webpackChunkName: "component---src-pages-oferta-laseroterapia-js" */),
  "component---src-pages-oferta-pielegniarstwo-js": () => import("./../../../src/pages/oferta/pielegniarstwo.js" /* webpackChunkName: "component---src-pages-oferta-pielegniarstwo-js" */),
  "component---src-pages-oferta-podologia-js": () => import("./../../../src/pages/oferta/podologia.js" /* webpackChunkName: "component---src-pages-oferta-podologia-js" */),
  "component---src-pages-opinie-klientow-js": () => import("./../../../src/pages/opinie-klientow.js" /* webpackChunkName: "component---src-pages-opinie-klientow-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-opinion-js": () => import("./../../../src/templates/opinion.js" /* webpackChunkName: "component---src-templates-opinion-js" */),
  "component---src-templates-podologia-js": () => import("./../../../src/templates/podologia.js" /* webpackChunkName: "component---src-templates-podologia-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

